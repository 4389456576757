import './styles.css';
import { initializeMemeCard } from './memegen';
import { initializeStakingCard } from './staking';
import { initializeTicketsCard } from './tickets';
import { Connection, PublicKey } from '@solana/web3.js';
import { cardContent } from './cardcontent';
import { createRoot } from 'react-dom/client';
import React from 'react';
import { WalletButtonComponent } from './WalletButton';
import { WalletContextProvider } from './WalletProvider';
import songUrl from '../assets/song.mp4';
const audio = new Audio(songUrl);
audio.volume = 0.88;
let isPlaying = false;
// Initialize program constants
export const programID = new PublicKey('RAPRxaJc8Xz2bqD3deoQLqT5noS8zsw1zWwBu95mijo');
export const raprMintAddress = new PublicKey('RAPRz9fd87y9qcBGj1VVqUbbUM6DaBggSDA58zc3N2b');
export const usdcMintAddress = new PublicKey('EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v');
export const connection = new Connection('https://mainnet.helius-rpc.com/?api-key=9bf9b93b-feb3-4b20-8dc8-32a5a1b46b4d', 'confirmed');
const content = document.getElementById('content');
const exitButton = document.getElementById('exitButton');
// Initialize global wallet state
export let wallet = null;
// Create a custom event for wallet changes
export const walletChangeEvent = new Event('walletChange');
// Function to update global wallet state
export function updateGlobalWallet(newWallet) {
    wallet = newWallet;
    window.wallet = newWallet;
    window.dispatchEvent(walletChangeEvent);
}
// Initialize wallet state from window if available
if (window.wallet) {
    updateGlobalWallet(window.wallet);
}
// Listen for wallet changes
window.addEventListener('walletChange', () => {
    if (window.wallet !== wallet) {
        updateGlobalWallet(window.wallet);
    }
});
function fadeElement(element, fadeIn, duration, callback) {
    element.style.opacity = fadeIn ? '0' : '1';
    element.style.transition = `opacity ${duration}ms`;
    setTimeout(() => {
        element.style.opacity = fadeIn ? '1' : '0';
        if (callback)
            setTimeout(callback, duration);
    }, 50);
}
const buttonInfo = [
    { name: "STAKING", fontSize: "25px" },
    { name: "TICKETS", fontSize: "25px" },
    { name: "MEME", fontSize: "25px" }
];
function pushState(state, replace = false) {
    if (replace) {
        history.replaceState(state, '');
    }
    else {
        history.pushState(state, '');
    }
}
function showMenu() {
    const cardElement = document.querySelector('.card');
    if (cardElement) {
        fadeElement(cardElement, false, 500, () => {
            updateMenuContent();
        });
    }
    else {
        updateMenuContent();
    }
    const exitButton = document.getElementById('exitButton');
    if (exitButton) {
        exitButton.innerHTML = '<span class="button-text">HOME</span>';
    }
    const centerContainer = document.querySelector('.center-container');
    if (centerContainer) {
        centerContainer.style.display = 'flex';
        fadeElement(centerContainer, true, 500);
    }
    pushState({ action: 'showMenu' }, true);
}
function updateMenuContent() {
    content.innerHTML = `
        <div id="menu">
            ${buttonInfo.map((button, index) => `
                <button class="menuButton" style="font-size: ${button.fontSize};" data-index="${index}"><span class="button-text">${button.name}</span></button>
            `).join('')}
        </div>
    `;
    const logo = document.getElementById('logo');
    if (logo) {
        logo.style.display = 'block';
        fadeElement(logo, true, 500);
    }
    const menuElement = document.getElementById('menu');
    if (menuElement) {
        fadeElement(menuElement, true, 500);
    }
    const menuButtons = document.querySelectorAll('.menuButton');
    menuButtons.forEach(button => {
        button.addEventListener('click', function () {
            const index = parseInt(this.getAttribute('data-index') || '0', 10);
            showCard(index);
        });
    });
}
function showCard(index) {
    const card = cardContent[index];
    const menuElement = document.getElementById('menu');
    const centerContainer = document.querySelector('.center-container');
    pushState({ action: 'showCard', index: index });
    if (menuElement)
        fadeElement(menuElement, false, 500);
    if (centerContainer) {
        fadeElement(centerContainer, false, 500, () => {
            centerContainer.style.display = 'none';
            content.innerHTML = `
                <div class="card ${index === 2 ? 'meme-card' : ''}" data-index="${index}">
                    <div class="card-header">${card.title}</div>
                    <div class="card-content">
                        ${card.content}
                    </div>
                </div>
            `;
            const cardElement = document.querySelector('.card');
            if (cardElement) {
                cardElement.style.removeProperty('margin-top');
                cardElement.style.removeProperty('top');
                fadeElement(cardElement, true, 500, () => {
                    if (index === 0) {
                        initializeStakingCard(wallet);
                    }
                    else if (index === 1) {
                        initializeTicketsCard(wallet);
                    }
                    else if (index === 2) {
                        initializeMemeCard();
                    }
                });
            }
        });
    }
    if (exitButton) {
        exitButton.innerHTML = '<span class="button-text">HOME</span>';
    }
}
function checkLogoMenuIntersection() {
    const logo = document.getElementById('logo');
    const menu = document.getElementById('menu');
    if (!logo || !menu) {
        console.warn('Logo or menu element not found');
        return;
    }
    const logoRect = logo.getBoundingClientRect();
    const menuRect = menu.getBoundingClientRect();
    if (logoRect.bottom > menuRect.top + menuRect.height * 0.25) {
        logo.style.opacity = '1';
        logo.classList.add('scaled-downdown');
    }
    else if (logoRect.bottom > menuRect.top) {
        logo.style.opacity = '1';
        logo.classList.add('scaled-down');
    }
    else {
        logo.style.opacity = '1';
        logo.classList.remove('scaled-down');
    }
}
function createTicker(text, targetElement) {
    const repeatedText = text.repeat(20);
    targetElement.innerHTML = `
        <span>${repeatedText}</span>
        <span>${repeatedText}</span>
    `;
}
window.addEventListener('DOMContentLoaded', () => {
    showMenu();
    const topBar = document.getElementById('topBar');
    if (topBar) {
        topBar.innerHTML = `
            <div id="tickerContainer">
                <div class="ticker-content"></div>
            </div>
            <div id="buttonContainer">
                <button id="exitButton"><span class="button-text">HOME</span></button>
                <div id="wallet-button-container"></div>
            </div>
        `;
        const tickerContent = topBar.querySelector('.ticker-content');
        if (tickerContent) {
            createTicker(" SPECIAL MONEY FRIENDS • ", tickerContent);
        }
        const walletContainer = document.getElementById('wallet-button-container');
        if (walletContainer) {
            const root = createRoot(walletContainer);
            root.render(React.createElement(WalletContextProvider, null, React.createElement(WalletButtonComponent, null)));
        }
        const exitButton = document.getElementById('exitButton');
        if (exitButton) {
            exitButton.addEventListener('click', showMenu);
        }
    }
    const logoElement = document.getElementById('logo');
    if (logoElement) {
        logoElement.addEventListener('click', () => {
            if (isPlaying) {
                audio.pause();
                isPlaying = false;
            }
            else {
                audio.play();
                isPlaying = true;
            }
        });
    }
    setTimeout(checkLogoMenuIntersection, 100);
});
window.addEventListener('popstate', (event) => {
    if (event.state && event.state.action === 'showCard') {
        showCard(event.state.index);
    }
    else {
        showMenu();
    }
});
